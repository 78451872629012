import React from 'react'
import { EMatchType } from '@unfoldrtech/portal-mic'
import { CurrentMatchTypeText } from '../../../Global'

export const TableDataCellMatchType = ({
  dataValue,
}: {
  dataValue: EMatchType
}) => {
  return <CurrentMatchTypeText matchType={dataValue} />
}
