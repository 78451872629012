import { EOrgType } from '@unfoldrtech/portal-mic'
import { EChannelTypeGlobal, EReportColumnHeader1 } from '../../../models/core'
import { TGlobalMetrics } from '../models'

export const getDefaultMetric = ({
  orgType,
  channelType,
  hasRevenue,
  allowedMetricList,
}: {
  orgType: EOrgType
  channelType?: EChannelTypeGlobal
  hasRevenue?: boolean
  allowedMetricList: TGlobalMetrics[]
}) => {
  // All retailer pages should default to Costs
  if (orgType === EOrgType.Retailer) {
    return EReportColumnHeader1.Costs
  }

  // All advertiser pages without channelType or channel type SP should default to Revenue
  if (
    (!channelType || channelType === EChannelTypeGlobal.SponsoredProduct) &&
    hasRevenue &&
    allowedMetricList.includes(EReportColumnHeader1.Revenue)
  ) {
    return EReportColumnHeader1.Revenue
  }

  return EReportColumnHeader1.Impressions
}
