import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { ReportingAudienceChartWrapper } from './ReportingAudienceChartWrapper'
import { ReportingAudienceTableWrapper } from './ReportingAudienceTableWrapper'

import {
  IDAReportAudiencesTable,
  TReportExportType,
  TReportingFilters,
} from '../../../../models'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
} from '../../../../models/contexts'

import {
  selectAudienceChartReportingFilters,
  setAdGroupIds,
  setCampaignIds,
} from '../../../../store/reportingFilters'

import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDatasetWhitelistKey } from '../../models'
import {
  EParamChannelType,
  IDOOHReportAudiencesTable,
} from '../../../../models/core'
import { getAudienceEDatasetKeyFromProps } from '../helpers/get-audience-e-dataset-key-from-props'
import { convertEParamChannelTypeToEChannelTypeGlobal } from '../../../../utils/helpers'
import { convertEChannelTypeToEChannelTypeGlobal } from '../../../../utils/helpers/convert-e-channel-type-to-e-channel-type-global'

type IReportAudiencesTable = IDAReportAudiencesTable | IDOOHReportAudiencesTable

/**
 * One report template per entity
 * Ex: ReportCategoriesTemplate, ReportCampaignsTemplate, etc ...
 */
export const ReportingAudienceTemplate = () => {
  const pageTitle = 'reporting.header.audiences'

  const dispatch = useDispatch()
  const intl = useIntl()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)
  const { channelType: reportingFilterChannelType } = useSelector(
    selectAudienceChartReportingFilters
  )
  const channelType =
    convertEParamChannelTypeToEChannelTypeGlobal(
      reportingFilterChannelType as EParamChannelType
    ) || convertEChannelTypeToEChannelTypeGlobal(campaign.channelType)

  const [tableData, setTableData] = useState<IReportAudiencesTable>()

  const [reportingFiltersIsSetup, setReportingFiltersIsSetup] = useState(false)

  const onTableDataChange = (locationsTableData: IReportAudiencesTable) => {
    setTableData(locationsTableData)
  }
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) ||
      getAudienceEDatasetKeyFromProps(channelType, !!adGroup.id),
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  useEffect(() => {
    dispatch(
      setCampaignIds({
        campaignIds: campaign?.id,
      })
    )
    dispatch(
      setAdGroupIds({
        adGroupIds: adGroup?.id,
      })
    )
    setReportingFiltersIsSetup(true)
  }, [campaign.id, adGroup.id])

  return (
    <>
      {reportingFiltersIsSetup && (
        <ReportPageTemplateLayout
          title={pageTitle}
          isExportDisabled={Boolean(!tableData?.rows.length)}
          chartWrapper={<ReportingAudienceChartWrapper />}
          tableWrapper={
            <ReportingAudienceTableWrapper
              onTableDataChange={onTableDataChange}
            />
          }
          onExportData={onExportData}
        />
      )}
    </>
  )
}
