import React from 'react'
import { NumberText } from '../../../Global'

export const TableDataCellNewToBrandPurchases = ({
  dataValue,
}: {
  dataValue?: number
}) => {
  return <NumberText value={Number(dataValue)} />
}
