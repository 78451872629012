import React from 'react'
import { EChannelTypeGlobal } from '@unfoldrtech/portal-mic/dist/src/openapi/core'
import { CurrentGlobalChannelTypeText } from '../../../Global'

export const TableDataCellChannelType = ({
  dataValue,
}: {
  dataValue: EChannelTypeGlobal
}) => {
  return <CurrentGlobalChannelTypeText channelType={dataValue} />
}
