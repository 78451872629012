import React from 'react'
import I18NText from '../../I18NText'
import { EChannelTypeGlobal } from '../../../models/core'

export function CurrentGlobalChannelTypeText({
  channelType,
}: {
  channelType?: EChannelTypeGlobal
}) {
  if (channelType === EChannelTypeGlobal.DisplayAd) {
    return (
      <>
        <I18NText id="displayAd" />
      </>
    )
  }
  if (channelType === EChannelTypeGlobal.SponsoredProduct) {
    return (
      <>
        <I18NText id="sponsoredProduct" />
      </>
    )
  }

  if (channelType === EChannelTypeGlobal.DigitalOutOfHome) {
    return (
      <>
        <I18NText id="digital.out.of.home" />
      </>
    )
  }
  return <> </>
}
