import {
  TDAAudiencesReportTableFilters,
  TDOOHAudiencesReportTableFilters,
} from '../../../models/types'

export const selectAudienceTableReportingFilters = ({
  reportingFilters: reportingAudiencesTableFiltersState,
}: {
  reportingFilters:
    | TDAAudiencesReportTableFilters
    | TDOOHAudiencesReportTableFilters
}): TDAAudiencesReportTableFilters | TDOOHAudiencesReportTableFilters =>
  reportingAudiencesTableFiltersState
