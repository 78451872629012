import {
  TDACategoriesReportTableFilters,
  TSPCategoriesReportTableFilters,
} from '../../../models/types'

export const selectCategoriesTableReportingFilters = ({
  reportingFilters: reportingCategoriesTableFiltersState,
}: {
  reportingFilters:
    | TSPCategoriesReportTableFilters
    | TDACategoriesReportTableFilters
}): TSPCategoriesReportTableFilters | TDACategoriesReportTableFilters =>
  reportingCategoriesTableFiltersState
