import React from 'react'
import { CurrencyText } from '../../../Global'

export const TableDataCellNewToBrandRevenue = ({
  dataValue,
}: {
  dataValue?: number
}) => {
  return <CurrencyText value={Number(dataValue)} />
}
