import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { EOrgType } from '@unfoldrtech/portal-mic'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
  EDAReportAudiencesColumnHeader,
  EDatasetKey,
  EDOOHReportLocationsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../models'
import { TDatasetWhitelistKey } from '../models'
import { TGlobalTableDataType } from '../models/types/global-table-data.type'
import {
  EDAReportCampaignsColumnHeader,
  EDAReportKeywordsColumnHeader,
  EDAReportPositionsColumnHeader,
  EDAReportProductsColumnHeader,
  EDAReportCategoriesColumnHeader,
  EDOOHReportAudiencesColumnHeader,
  EDOOHReportCampaignsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  ESPReportCampaignsColumnHeader,
  ESPReportKeywordsColumnHeader,
  ESPReportCategoriesColumnHeader,
} from '../../../models/core'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'

export const useGetDatasetKeyTableWhitelist = ({
  key,
}: {
  key: TDatasetWhitelistKey
}) => {
  const intl = useIntl()
  const featureFlags = useFeatureFlags()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const orgType = appContext.userOrg!.type

  const whiteList = {} as Record<
    TDatasetWhitelistKey,
    Array<TGlobalTableDataType>
  >

  whiteList[EDatasetKey.LocationDooh] = []
  whiteList[EDatasetKey.AudienceDa] = []
  whiteList[EDatasetKey.AudienceDaAdgroup] = []
  whiteList[EDatasetKey.AudienceDooh] = []
  whiteList[EDatasetKey.AudienceDoohAdgroup] = []
  whiteList[EDatasetKey.ProductSp] = []
  whiteList[EDatasetKey.ProductDa] = []
  whiteList[EDatasetKey.PositionDa] = []
  whiteList[EDatasetKey.ProductSpAdgroup] = []
  whiteList[EDatasetKey.ProductDa] = []
  whiteList[EDatasetKey.ProductDaAdgroup] = []
  whiteList[EDatasetKey.PositionDa] = []
  whiteList[EDatasetKey.PositionDaAdgroup] = []
  whiteList[EDatasetKey.CampaignGeneric] = []
  whiteList[EDatasetKey.CampaignSp] = []
  whiteList[EDatasetKey.CampaignDa] = []
  whiteList[EDatasetKey.CampaignDooh] = []
  whiteList[EDatasetKey.KeywordSpAdgroup] = []
  whiteList[EDatasetKey.KeywordDaAdgroup] = []
  whiteList[EDatasetKey.CategorySpAdgroup] = []
  whiteList[EDatasetKey.CategoryDaAdgroup] = []

  const whitelist: Record<TDatasetWhitelistKey, Array<TGlobalTableDataType>> = {
    'LOCATION-DOOH': [
      {
        header: EDOOHReportLocationsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.locations`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.locations.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Type,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Type
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Type
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.AdgroupName,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.AdgroupName
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.AdgroupName
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Playouts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Playouts
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportLocationsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Costs
          }.tooltip`,
        }),
        isCurrency: true,
      },
      {
        header: EDOOHReportLocationsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Cpm
          }.tooltip`,
        }),
        isCurrency: true,
      },
    ],
    'AUDIENCE-DA': [
      {
        header: EDAReportAudiencesColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Adgroups,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Adgroups
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Adgroups
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Campaigns,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Campaigns
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Campaigns
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Positions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Positions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Positions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'AUDIENCE-DA-ADGROUP': [
      {
        header: EDAReportAudiencesColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Positions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Positions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Positions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportAudiencesColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Cpm
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'AUDIENCE-DOOH': [
      {
        header: EDOOHReportAudiencesColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Adgroups,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Adgroups
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Adgroups
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Campaigns,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Campaigns
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Campaigns
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Positions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Positions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Positions
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Playouts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Playouts
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Impressions
          }.tooltip`,
        }),
      },
    ],
    'AUDIENCE-DOOH-ADGROUP': [
      {
        header: EDOOHReportAudiencesColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.audiences.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Positions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Positions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Positions
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Playouts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Playouts
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportAudiencesColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportAudiencesColumnHeader.Cpm
          }.tooltip`,
        }),
      },
    ],
    'PRODUCT-SP': [
      {
        header: ESPReportProductsColumnHeader.Image,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Image
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Image
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Campaigns,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Campaigns
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Campaigns
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Adgroups,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Adgroups
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Adgroups
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.InStock,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'PRODUCT-SP-ADGROUP': [
      {
        header: ESPReportProductsColumnHeader.Image,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Image
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Image
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.InStock,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'PRODUCT-DA': [
      {
        header: EDAReportProductsColumnHeader.Image,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Image
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Image
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Campaigns,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Campaigns
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Campaigns
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Adgroups,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Adgroups
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Adgroups
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.InStock,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'PRODUCT-DA-ADGROUP': [
      {
        header: EDAReportProductsColumnHeader.Image,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Image
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Image
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.products.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: ESPReportProductsColumnHeader.InStock,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.InStock
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'POSITION-DA': [
      {
        header: EDAReportPositionsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Name
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Name
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Adgroups,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Adgroups
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Adgroups
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Cpm
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.WinRate
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'POSITION-DA-ADGROUP': [
      {
        header: EDAReportPositionsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Name
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Name
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Placement,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Placement
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Placement
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.BidPrice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.BidPrice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.BidPrice
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Cpm
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.WinRate
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportPositionsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'CAMPAIGN-GENERIC': [
      {
        header: EGenericReportCampaignsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.CampaignType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.CampaignType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.CampaignType
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.AdvertiserName,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.AdvertiserName
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.AdvertiserName
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.ChannelType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.ChannelType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.ChannelType
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Budget,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Budget
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Budget
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.StartDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.StartDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.StartDate
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.EndDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.EndDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.EndDate
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.WinRate
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.NewToBrandPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.NewToBrandPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.NewToBrandPurchases
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.NewToBrandRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.NewToBrandRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.NewToBrandRevenue
          }.tooltip`,
        }),
      },
      {
        header: EGenericReportCampaignsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'CAMPAIGN-SP': [
      {
        header: ESPReportCampaignsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.CampaignType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.CampaignType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.CampaignType
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.AdvertiserName,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.AdvertiserName
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.AdvertiserName
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.ChannelType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.ChannelType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.ChannelType
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Budget,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Budget
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Budget
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.StartDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.StartDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.StartDate
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.EndDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.EndDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.EndDate
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Cpc
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Cpc
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.AvgRank
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.AvgRank
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.WinRate
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.NewToBrandPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.NewToBrandPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.NewToBrandPurchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.NewToBrandRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.NewToBrandRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.NewToBrandRevenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'CAMPAIGN-DA': [
      {
        header: EDAReportCampaignsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.CampaignType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.CampaignType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.CampaignType
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.AdvertiserName,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.AdvertiserName
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.AdvertiserName
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.ChannelType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.ChannelType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.ChannelType
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Budget,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Budget
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Budget
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.StartDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.StartDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.StartDate
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.EndDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.EndDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.EndDate
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Cpm
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.WinRate
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'CAMPAIGN-DOOH': [
      {
        header: EDOOHReportCampaignsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.campaigns.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.CampaignType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.CampaignType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.CampaignType
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.AdvertiserName,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.AdvertiserName
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.AdvertiserName
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.ChannelType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.ChannelType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.ChannelType
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.Budget,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Budget
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Budget
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.StartDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.StartDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.StartDate
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.EndDate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.EndDate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.EndDate
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Playouts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Playouts
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Costs
          }.tooltip`,
        }),
        isCurrency: true,
      },
      {
        header: EDOOHReportCampaignsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Cpm
          }.tooltip`,
        }),
        isCurrency: true,
      },
      {
        header: EDOOHReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: EDOOHReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.WinRate
          }.tooltip`,
        }),
      },
    ],
    'KEYWORD-SP-ADGROUP': [
      {
        header: ESPReportKeywordsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.keywords`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.keywords.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.MatchType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.MatchType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.MatchType
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.BidPrice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.BidPrice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.BidPrice
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Cpc
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Cpc
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.AvgRank
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.AvgRank
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.NewToBrandPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.NewToBrandPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.NewToBrandPurchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.NewToBrandRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.NewToBrandRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.NewToBrandRevenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportKeywordsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'KEYWORD-DA-ADGROUP': [
      {
        header: EDAReportKeywordsColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.keywords`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.keywords.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.MatchType,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.MatchType
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.MatchType
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.BidPrice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.BidPrice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.BidPrice
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportKeywordsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Cpm
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCampaignsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'CATEGORY-SP-ADGROUP': [
      {
        header: ESPReportCategoriesColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.categories`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.categories.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.BidPrice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.BidPrice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.BidPrice
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Cpc
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Cpc
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.AvgRank
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.AvgRank
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.WinRate
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.NewToBrandPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.NewToBrandPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.NewToBrandPurchases
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.NewToBrandRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.NewToBrandRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.NewToBrandRevenue
          }.tooltip`,
        }),
      },
      {
        header: ESPReportCategoriesColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCategoriesColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
    'CATEGORY-DA-ADGROUP': [
      {
        header: EDAReportCategoriesColumnHeader.Name,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.categories`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.categories.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Status,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Status
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Status
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.BidPrice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.BidPrice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.BidPrice
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Impressions
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Impressions
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Clicks
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Clicks
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Ctr
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Ctr
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.UniqueReach
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.UniqueReach
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Frequency
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Frequency
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Costs
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Costs
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Cpm
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Cpm
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.ShareOfVoice
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.ShareOfVoice
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.WinRate
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.WinRate
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Carts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Carts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.PvCarts
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.PvCarts
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Purchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Purchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.PvPurchases
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.PvPurchases
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Revenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Revenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.PvRevenue
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.PvRevenue
          }.tooltip`,
        }),
      },
      {
        header: EDAReportCategoriesColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Roas
          }`,
        }),
        translatedTooltipMetricName: intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            EDAReportCategoriesColumnHeader.Roas
          }.tooltip`,
        }),
      },
    ],
  }

  whiteList[key] = whitelist[key]

  if (adGroup.id && key === EDatasetKey.LocationDooh) {
    whiteList[EDatasetKey.LocationDooh] = whiteList[
      EDatasetKey.LocationDooh
    ].filter(
      (column) => column.header !== EDOOHReportLocationsColumnHeader.AdgroupName
    )
  }
  if (adGroup.id && key === EDatasetKey.AudienceDa) {
    whiteList[EDatasetKey.AudienceDa] = whiteList[
      EDatasetKey.AudienceDa
    ].filter(
      (column) => column.header !== EDOOHReportLocationsColumnHeader.AdgroupName
    )
  }
  if (campaign.id && key === EDatasetKey.ProductSp) {
    whiteList[EDatasetKey.ProductSp] = whiteList[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductSp) {
    whiteList[EDatasetKey.ProductSp] = whiteList[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductSp) {
    whiteList[EDatasetKey.ProductSp] = whiteList[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Status
    )
    whiteList[EDatasetKey.ProductSp] = whiteList[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.AvgRank
    )
  }
  if (campaign.id && key === EDatasetKey.ProductDa) {
    whiteList[EDatasetKey.ProductDa] = whiteList[EDatasetKey.ProductDa].filter(
      (column) => column.header !== EDAReportProductsColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductDa) {
    whiteList[EDatasetKey.ProductDa] = whiteList[EDatasetKey.ProductDa].filter(
      (column) => column.header !== EDAReportProductsColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductDa) {
    whiteList[EDatasetKey.ProductDa] = whiteList[EDatasetKey.ProductDa].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Status
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignGeneric) {
    whiteList[EDatasetKey.CampaignGeneric] = whiteList[
      EDatasetKey.CampaignGeneric
    ].filter(
      (column) =>
        column.header !== EGenericReportCampaignsColumnHeader.AdvertiserName
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignSp) {
    whiteList[EDatasetKey.CampaignSp] = whiteList[
      EDatasetKey.CampaignSp
    ].filter(
      (column) =>
        column.header !== ESPReportCampaignsColumnHeader.AdvertiserName
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignDa) {
    whiteList[EDatasetKey.CampaignDa] = whiteList[
      EDatasetKey.CampaignDa
    ].filter(
      (column) =>
        column.header !== EDAReportCampaignsColumnHeader.AdvertiserName
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignDooh) {
    whiteList[EDatasetKey.CampaignDooh] = whiteList[
      EDatasetKey.CampaignDooh
    ].filter(
      (column) =>
        column.header !== EDOOHReportCampaignsColumnHeader.AdvertiserName
    )
  }

  if (
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] &&
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].length
  ) {
    whiteList[key] = whiteList[key].filter(
      (column) =>
        !featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].includes(column.header)
    )
  }

  return whiteList[key]
}
