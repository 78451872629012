import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingAudiencesTableV3 from '../../../../hooks/use-get-advertiser-reporting-audiences-table-v3'
import useGetAdvertiserReportingAudiencesChartV3 from '../../../../hooks/use-get-advertiser-reporting-audiences-chart-v3'
import useGetRetailerReportingAudiencesTableV3 from '../../../../hooks/use-get-retailer-reporting-audiences-table-v3'
import useGetRetailerReportingAudiencesChartV3 from '../../../../hooks/use-get-retailer-reporting-audiences-chart-v3'

export const getTableAndChartAudiencesFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingAudiencesTableV3
    chartFn = useGetAdvertiserReportingAudiencesChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingAudiencesTableV3
    chartFn = useGetRetailerReportingAudiencesChartV3
  }

  return { tableFn, chartFn }
}
