import { EChannelTypeGlobal, EDatasetKey } from '../../../../models/core'

export function getAudienceEDatasetKeyFromProps(
  channelType?: EChannelTypeGlobal,
  isAdgroup?: boolean
) {
  if (channelType === EChannelTypeGlobal.DisplayAd) {
    if (isAdgroup) {
      return EDatasetKey.AudienceDaAdgroup
    }
    return EDatasetKey.AudienceDa
  }
  if (channelType === EChannelTypeGlobal.DigitalOutOfHome) {
    if (isAdgroup) {
      return EDatasetKey.AudienceDoohAdgroup
    }
    return EDatasetKey.AudienceDooh
  }
  throw new Error('unsupported.channel.type')
}
