import {
  TDACampaignsReportChartFilters,
  TDOOHCampaignsReportChartFilters,
  TGenericCampaignsReportChartFilters,
  TSPCampaignsReportChartFilters,
} from '../../../models/types'

export const selectCampaignsChartReportingFilters = ({
  reportingFilters: reportingCampaignsChartFiltersState,
}: {
  reportingFilters:
    | TGenericCampaignsReportChartFilters
    | TSPCampaignsReportChartFilters
    | TDACampaignsReportChartFilters
    | TDOOHCampaignsReportChartFilters
}):
  | TGenericCampaignsReportChartFilters
  | TSPCampaignsReportChartFilters
  | TDACampaignsReportChartFilters
  | TDOOHCampaignsReportChartFilters => reportingCampaignsChartFiltersState
