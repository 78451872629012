import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { EDOOHTargetType, IDOOHAdGroup, IDOOHCampaign } from '../../../models'
import { getSelectedDoohAdGroup } from '../utils'

const setDoohCampaignAdGroupTargetType = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{
    targetType: EDOOHTargetType
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedDoohAdGroup(state, action.payload.adGroupId) ||
    ({} as IDOOHAdGroup)

  selectedAdGroup.targeting.targetType = action.payload.targetType
}
export default setDoohCampaignAdGroupTargetType
