import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconForecasting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="4.5" cy="13.2891" r="2" stroke="currentColor" />
    <circle cx="15.5" cy="13.2891" r="2" stroke="currentColor" />
    <path
      d="M9 5.78906C9.5 3.28906 8.10457 2.78906 7 2.78906C5.89543 2.78906 5 3.68449 5 4.78906"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M11.1047 5.78906C10.6047 3.28906 12.0002 2.78906 13.1047 2.78906C14.2093 2.78906 15.1047 3.68449 15.1047 4.78906"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M2.5 9.78906L4.72361 5.34185C4.893 5.00307 5.23926 4.78906 5.61803 4.78906H7.83439C8.44727 4.78906 8.91595 5.33536 8.82276 5.94112L8 11.2891"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 9.78906L15.2764 5.34185C15.107 5.00307 14.7607 4.78906 14.382 4.78906H12.1656C11.5527 4.78906 11.084 5.33536 11.1772 5.94112L12 11.2891"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.5 13.7891H11.5" stroke="currentColor" />
    <path
      d="M8 10.7891C8 10.7891 8.66667 11.7891 10 11.7891C11.3333 11.7891 12 10.7891 12 10.7891"
      stroke="currentColor"
    />
    <path
      d="M9 5.78906C9 5.78906 9.33333 6.28906 10 6.28906C10.6667 6.28906 11 5.78906 11 5.78906"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <circle cx="4.5" cy="13.2891" r="4" stroke="currentColor" />
    <circle cx="15.5" cy="13.2891" r="4" stroke="currentColor" />
  </svg>
)

export default SvgIconForecasting
