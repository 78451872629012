import { createSlice } from '@reduxjs/toolkit'
import { EStatus } from '@unfoldrtech/portal-mic'
import reducers from './reducers'
import { IDOOHAdGroup, IDOOHCampaign } from '../../models'

const INITIAL_CAMPAIGN_STATE: IDOOHCampaign = <IDOOHCampaign>{
  status: EStatus.Inactive,
  adGroupsList: [] as IDOOHAdGroup[],
}

export const campaignSlice = createSlice({
  name: 'doohCampaign',
  initialState: INITIAL_CAMPAIGN_STATE,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    resetDoohCampaign: () => INITIAL_CAMPAIGN_STATE,
    ...reducers,
  },
})

export const {
  resetDoohCampaign,
  setDoohCampaignDetails,
  setDoohCampaignSettings,
  setDoohCampaignAdGroup,
  setAdGroupAudience,
  setDoohCampaignAdGroupCreative,
  setAdGroupDayTimeTarget,
  setDoohCampaignAdGroupTargetType,
  removeDoohCampaignAdGroup,
  removeDoohCampaignAdGroupCreatives,
  resetAdGroupDayTimeTarget,
} = campaignSlice.actions

export const doohCampaignReducer = campaignSlice.reducer
