import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { EEntityType, EParamSortOrder, EStatus } from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectCampaignsTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { AppContext, EDealType } from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import {
  TDACampaignsTableHeaders,
  TDOOHCampaignsTableHeaders,
  TGenericCampaignsTableHeaders,
  TSPCampaignsTableHeaders,
} from '../models'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import { TDatasetWhitelistKey, TGlobalTables } from '../../models'
import { TableDataCellCpc } from '../../components/table-data-cell/TableDataCellCpc'
import { TableDataCellClicks } from '../../components/table-data-cell/TableDataCellClicks'
import { TableDataCellAvgRank } from '../../components/table-data-cell/TableDataCellAvgRank'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import {
  EChannelTypeGlobal,
  EDAReportCampaignsColumnHeader,
  EDOOHReportCampaignsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  ESPReportCampaignsColumnHeader,
  IDAReportCampaignsTable,
  IDOOHReportCampaignsTable,
  IGenericReportCampaignsTable,
  ISPReportCampaignsTable,
} from '../../../../models/core'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { getCampaignsEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-campaigns-e-dataset-key-from-channel-type-global'
import { getTableAndChartCampaignsFn } from '../helpers/get-table-and-chart-campaigns-fn'
import { TableDataCellAdvertiserName } from '../../components/table-data-cell/TableDataCellAdvertiserName'
import { TableDataCellStatusCampaignWithUpdate } from '../../components/table-data-cell/TableDataCellStatusCampaignWithUpdate'
import { TableDataCellChannelType } from '../../components/table-data-cell/TableDataCellChannelType'
import { TableDataCellBudget } from '../../components/table-data-cell/TableDataCellBudget'
import { TableDataCellStartDate } from '../../components/table-data-cell/TableDataCellStartDate'
import { TableDataCellEndDate } from '../../components/table-data-cell/TableDataCellEndDate'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellWinRate } from '../../components/table-data-cell/TableDataCellWinRate'
import { TableDataCellNewToBrandPurchases } from '../../components/table-data-cell/TableDataCellNewToBrandPurchases'
import { TableDataCellNewToBrandRevenue } from '../../components/table-data-cell/TableDataCellNewToBrandRevenue'
import { TableDataCellPlayouts } from '../../components/table-data-cell/TableDataCellPlayouts'
import { TableDataCellCampaignName } from '../../components/table-data-cell/TableDataCellCampaignName'
import { TableDataCellCampaignType } from '../../components/table-data-cell/TableDataCellCampaignType'
import useShowToast from '../../../../hooks/useShowToast'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import { TablePreloader } from '../../components/table-preloader'

type TCampaignsTableHeaders = TGenericCampaignsTableHeaders &
  TSPCampaignsTableHeaders &
  TDACampaignsTableHeaders &
  TDOOHCampaignsTableHeaders
type IReportCampaignsTable =
  | IGenericReportCampaignsTable
  | ISPReportCampaignsTable
  | IDAReportCampaignsTable
  | IDOOHReportCampaignsTable

export const ReportingCampaignsTableWrapper = ({
  onTableDataChange,
  channelType,
}: {
  onTableDataChange: (
    productsTableData:
      | IGenericReportCampaignsTable
      | ISPReportCampaignsTable
      | IDAReportCampaignsTable
      | IDOOHReportCampaignsTable
  ) => void
  channelType?: EChannelTypeGlobal
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [appContext] = useContext(AppContext)

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectCampaignsTableReportingFilters
  )
  const refreshTable = useSelector(selectRefreshTable)
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const hookFn = getTableAndChartCampaignsFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const [sortBy, setSortBy] = useState<TCampaignsTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()
  const [tableParamsUpdated, setTableParamsUpdated] = useState<boolean>()

  const [errorUpdateStatus, setErrorUpdateStatus] = useState<TTranslationKey>()
  const [showUpdateStatusErrorToast, setShowUpdateStatusErrorToast] =
    useShowToast(false)

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    channelType,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TCampaignsTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportCampaignsTable)

  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
      getCampaignsEDatasetKeyFromChannelTypeGlobal(channelType),
  })

  const processTableDataResponse = (tableDataRes: IReportCampaignsTable) => {
    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === ESPReportCampaignsColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TCampaignsTableHeaders]
        const navLink = `../campaigns/${row.id}`

        switch (header) {
          case EGenericReportCampaignsColumnHeader.Name:
          case ESPReportCampaignsColumnHeader.Name:
          case EDAReportCampaignsColumnHeader.Name:
          case EDOOHReportCampaignsColumnHeader.Name:
            dataCell = (
              <TableDataCellCampaignName
                dataValue={dataValue?.toString()}
                navigate={() => {
                  navigate(navLink, { state: row })
                }}
              />
            )
            break
          case EGenericReportCampaignsColumnHeader.CampaignType:
          case ESPReportCampaignsColumnHeader.CampaignType:
          case EDAReportCampaignsColumnHeader.CampaignType:
          case EDOOHReportCampaignsColumnHeader.CampaignType:
            dataCell = (
              <TableDataCellCampaignType dataValue={dataValue as EDealType} />
            )
            break
          case EGenericReportCampaignsColumnHeader.AdvertiserName:
          case ESPReportCampaignsColumnHeader.AdvertiserName:
          case EDAReportCampaignsColumnHeader.AdvertiserName:
          case EDOOHReportCampaignsColumnHeader.AdvertiserName:
            dataCell = (
              <TableDataCellAdvertiserName dataValue={dataValue?.toString()} />
            )
            break
          case EGenericReportCampaignsColumnHeader.Status:
          case ESPReportCampaignsColumnHeader.Status:
          case EDAReportCampaignsColumnHeader.Status:
          case EDOOHReportCampaignsColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusCampaignWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                orgType={orgType}
                id={row.id}
                key={`${row.id}-${sortBy}-${sortOrder}`}
                isDirectDeal={row.campaign_type !== EDealType.Programmatic}
                setErrorUpdateStatus={setErrorUpdateStatus}
                setShowUpdateStatusErrorToast={setShowUpdateStatusErrorToast}
              />
            )
            break
          case EGenericReportCampaignsColumnHeader.ChannelType:
          case ESPReportCampaignsColumnHeader.ChannelType:
          case EDAReportCampaignsColumnHeader.ChannelType:
          case EDOOHReportCampaignsColumnHeader.ChannelType:
            dataCell = (
              <TableDataCellChannelType
                dataValue={dataValue as EChannelTypeGlobal}
              />
            )
            break
          case EGenericReportCampaignsColumnHeader.Budget:
          case ESPReportCampaignsColumnHeader.Budget:
          case EDAReportCampaignsColumnHeader.Budget:
          case EDOOHReportCampaignsColumnHeader.Budget:
            dataCell = <TableDataCellBudget dataValue={dataValue as number} />
            break
          case EGenericReportCampaignsColumnHeader.StartDate:
          case ESPReportCampaignsColumnHeader.StartDate:
          case EDAReportCampaignsColumnHeader.StartDate:
          case EDOOHReportCampaignsColumnHeader.StartDate:
            dataCell = (
              <TableDataCellStartDate dataValue={dataValue as string} />
            )
            break
          case EGenericReportCampaignsColumnHeader.EndDate:
          case ESPReportCampaignsColumnHeader.EndDate:
          case EDAReportCampaignsColumnHeader.EndDate:
          case EDOOHReportCampaignsColumnHeader.EndDate:
            dataCell = <TableDataCellEndDate dataValue={dataValue as string} />
            break
          case EGenericReportCampaignsColumnHeader.Impressions:
          case ESPReportCampaignsColumnHeader.Impressions:
          case EDAReportCampaignsColumnHeader.Impressions:
          case EDOOHReportCampaignsColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportCampaignsColumnHeader.Clicks:
          case ESPReportCampaignsColumnHeader.Clicks:
          case EDAReportCampaignsColumnHeader.Clicks:
            dataCell = <TableDataCellClicks dataValue={Number(dataValue)} />
            break

          case EGenericReportCampaignsColumnHeader.Ctr:
          case ESPReportCampaignsColumnHeader.Ctr:
          case EDAReportCampaignsColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EGenericReportCampaignsColumnHeader.Costs:
          case ESPReportCampaignsColumnHeader.Costs:
          case EDAReportCampaignsColumnHeader.Costs:
          case EDOOHReportCampaignsColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EGenericReportCampaignsColumnHeader.Carts:
          case ESPReportCampaignsColumnHeader.Carts:
          case EDAReportCampaignsColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EGenericReportCampaignsColumnHeader.Purchases:
          case ESPReportCampaignsColumnHeader.Purchases:
          case EDAReportCampaignsColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break

          case EGenericReportCampaignsColumnHeader.Revenue:
          case ESPReportCampaignsColumnHeader.Revenue:
          case EDAReportCampaignsColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EGenericReportCampaignsColumnHeader.Roas:
          case ESPReportCampaignsColumnHeader.Roas:
          case EDAReportCampaignsColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          case EGenericReportCampaignsColumnHeader.ShareOfVoice:
          case ESPReportCampaignsColumnHeader.ShareOfVoice:
          case EDAReportCampaignsColumnHeader.ShareOfVoice:
          case EDOOHReportCampaignsColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportCampaignsColumnHeader.WinRate:
          case ESPReportCampaignsColumnHeader.WinRate:
          case EDAReportCampaignsColumnHeader.WinRate:
          case EDOOHReportCampaignsColumnHeader.WinRate:
            dataCell = <TableDataCellWinRate dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.Cpm:
          case EDOOHReportCampaignsColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          case EGenericReportCampaignsColumnHeader.NewToBrandPurchases:
          case ESPReportCampaignsColumnHeader.NewToBrandPurchases:
            dataCell = (
              <TableDataCellNewToBrandPurchases dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportCampaignsColumnHeader.NewToBrandRevenue:
          case ESPReportCampaignsColumnHeader.NewToBrandRevenue:
            dataCell = (
              <TableDataCellNewToBrandRevenue dataValue={Number(dataValue)} />
            )
            break
          // SP Exclusive
          case ESPReportCampaignsColumnHeader.Cpc:
            dataCell = <TableDataCellCpc dataValue={Number(dataValue)} />
            break
          case ESPReportCampaignsColumnHeader.AvgRank:
            dataCell = <TableDataCellAvgRank dataValue={Number(dataValue)} />
            break
          // DA Exclusive
          case EDAReportCampaignsColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EDAReportCampaignsColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          // DOOH Exclusive
          case EDOOHReportCampaignsColumnHeader.Playouts:
            dataCell = <TableDataCellPlayouts dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate) {
      setTableParamsUpdated(true)
    }
  }, [startDate, endDate, channelType])

  useEffect(() => {
    if (sortBy || sortOrder) {
      setTableParamsUpdated(true)
    }
  }, [sortBy, sortOrder])

  useEffect(() => {
    if (tableParamsUpdated) {
      refetch()
      setTableParamsUpdated(false)
    }
  }, [tableParamsUpdated])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(
        tableDataResponse as ISPReportCampaignsTable & IDAReportCampaignsTable
      )
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.Campaign, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateStatusErrorToast}
        onClose={() => {
          setShowUpdateStatusErrorToast(false)
          setErrorUpdateStatus(undefined)
        }}
        translationKey={errorUpdateStatus}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
