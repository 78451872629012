import {
  EDOOHLocation,
  EDOOHTargetType,
  IDOOHAdGroupCityLocationTargetingEntity,
  IDOOHAdGroupStoreLocationTargetingEntity,
  IDOOHAdGroupTargeting,
} from '../../models'

export const getLocationTypeByTargetType = (
  targeting: IDOOHAdGroupTargeting
): EDOOHLocation | undefined => {
  if (
    [
      EDOOHTargetType.PositionOnly,
      EDOOHTargetType.AudienceAndPosition,
    ].includes(targeting.targetType)
  ) {
    return EDOOHLocation.ALL_STORES
  }

  if (targeting.targetType === EDOOHTargetType.LocationAndPosition) {
    const isStore = (
      targeting.locationsList as IDOOHAdGroupStoreLocationTargetingEntity
    )?.storeLocationsList?.length

    const isCity = (
      targeting.locationsList as IDOOHAdGroupCityLocationTargetingEntity
    )?.cityLocationsList?.length

    if (isStore) {
      return EDOOHLocation.STORES
    }
    if (isCity) {
      return EDOOHLocation.CITIES
    }
  }
}
