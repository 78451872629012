import React from 'react'
import { DateText } from '../../../Global'

export const TableDataCellStartDate = ({
  dataValue,
}: {
  dataValue?: string
}) => {
  return <DateText value={dataValue} />
}
