import { EChannelType, EChannelTypeGlobal } from '../../models/core'

export function convertEChannelTypeToEChannelTypeGlobal(
  channelType?: EChannelType
) {
  switch (channelType) {
    case EChannelType.SponsoredProduct:
      return EChannelTypeGlobal.SponsoredProduct
    case EChannelType.DisplayAd:
      return EChannelTypeGlobal.DisplayAd
    case 'DOOH' as EChannelType:
      return EChannelTypeGlobal.DigitalOutOfHome
    case 'DIGITAL_OUT_OF_HOME' as EChannelType:
      return EChannelTypeGlobal.DigitalOutOfHome
    default:
      break
  }
}
