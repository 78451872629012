import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDAAudiencesReportChartFilters,
  TDOOHAudiencesReportChartFilters,
  TPPAdvertiserId,
} from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  EParamChannelTypeGlobal,
  IReportingAudiencesChartResponse,
} from '../models/core'

export default function useGetAdvertiserReportingAudiencesChartV3({
  advertiserId,
  retailerId,
  campaignId,
  adGroupId,
  channelType,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & (TDAAudiencesReportChartFilters | TDOOHAudiencesReportChartFilters) &
  TPPAdvertiserId) {
  const queryKeyName = `getAdvertiserReportingAudiencesChart_${channelType}_${advertiserId}_${retailerId}`

  return useQuery<
    AxiosResponse<IReportingAudiencesChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate, channelType }],
    () => {
      return coreAPI
        .v2AdvertiserReporting()
        .getAdvertiserAudiencesReportChart(
          advertiserId,
          generateCorrelationId(),
          retailerId,
          campaignId,
          adGroupId,
          channelType as EParamChannelTypeGlobal,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone()
        )
    },
    { suspense, enabled }
  )
}
