import React from 'react'
import { NOT_AVAILABLE } from '../../../../utils/constants'
import {
  EllipsisContainer,
  TooltipWrapper,
  Text,
  PageLinkButton,
} from '../../../Global'

export const TableDataCellCampaignName = ({
  dataValue = NOT_AVAILABLE,
  navigate = () => {},
}: {
  dataValue?: string
  navigate: () => void
}) => {
  return (
    <TooltipWrapper showTooltip={true} tooltip={dataValue}>
      <EllipsisContainer maxWidth="240px">
        <PageLinkButton onClick={navigate}>
          <Text>{dataValue}</Text>
        </PageLinkButton>
      </EllipsisContainer>
    </TooltipWrapper>
  )
}
