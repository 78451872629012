import { EParamChannelType } from '@unfoldrtech/portal-mic'
import { EChannelTypeGlobal } from '../../models/core'

export function convertEParamChannelTypeToEChannelTypeGlobal(
  channelType?: EParamChannelType
) {
  switch (channelType) {
    case EParamChannelType.SponsoredProduct:
      return EChannelTypeGlobal.SponsoredProduct
    case EParamChannelType.Da:
      return EChannelTypeGlobal.DisplayAd
    case EParamChannelType.Dooh:
      return EChannelTypeGlobal.DigitalOutOfHome
    default:
      break
  }
}
