import {
  TSPKeywordsReportChartFilters,
  TDAKeywordsReportChartFilters,
} from '../../../models'

export const selectKeywordsChartReportingFilters = ({
  reportingFilters: reportingKeywordsChartFiltersState,
}: {
  reportingFilters:
    | TSPKeywordsReportChartFilters
    | TDAKeywordsReportChartFilters
}): TSPKeywordsReportChartFilters | TDAKeywordsReportChartFilters =>
  reportingKeywordsChartFiltersState
