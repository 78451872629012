import { EChannelTypeGlobal, EDatasetKey } from '../../../../models/core'

export function getKeywordsEDatasetKeyFromChannelTypeGlobal(
  channelType?: EChannelTypeGlobal
) {
  switch (channelType) {
    case EChannelTypeGlobal.SponsoredProduct:
      return EDatasetKey.KeywordSpAdgroup
    case EChannelTypeGlobal.DisplayAd:
      return EDatasetKey.KeywordDaAdgroup
    default: // eventually we should add generic and throw error if other
      return EDatasetKey.KeywordSpAdgroup
  }
}
