import React from 'react'
import { StyledStatusCircle } from '../../../Global'

export const TableDataCellInStock = ({
  dataValue,
}: {
  dataValue?: boolean
}) => {
  return (
    <StyledStatusCircle
      margin="-4px 4px -4px 0px"
      width="var(--icon-size-sm)"
      height="var(--icon-size-sm)"
      active={dataValue}
    />
  )
}
