import { EChannelTypeGlobal, EDatasetKey } from '../../../../models/core'

export function getCampaignsEDatasetKeyFromChannelTypeGlobal(
  channelType?: EChannelTypeGlobal
) {
  switch (channelType) {
    case EChannelTypeGlobal.SponsoredProduct:
      return EDatasetKey.CampaignSp
    case EChannelTypeGlobal.DisplayAd:
      return EDatasetKey.CampaignDa
    case EChannelTypeGlobal.DigitalOutOfHome:
      return EDatasetKey.CampaignDooh
    default:
      return EDatasetKey.CampaignGeneric
  }
}
