import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingCategoriesChartV3 from '../../../../hooks/use-get-advertiser-reporting-categories-chart-v3'
import useGetAdvertiserReportingCategoriesTableV3 from '../../../../hooks/use-get-advertiser-reporting-categories-table-v3'
import useGetRetailerReportingCategoriesChartV3 from '../../../../hooks/use-get-retailer-reporting-categories-chart-v3'
import useGetRetailerReportingCategoriesTableV3 from '../../../../hooks/use-get-retailer-reporting-categories-table-v3'

export const getTableAndChartCategoriesFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingCategoriesTableV3
    chartFn = useGetAdvertiserReportingCategoriesChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingCategoriesTableV3
    chartFn = useGetRetailerReportingCategoriesChartV3
  }

  return { tableFn, chartFn }
}
