import {
  TSPCategoriesReportChartFilters,
  TDACategoriesReportChartFilters,
} from '../../../models'

export const selectCategoriesChartReportingFilters = ({
  reportingFilters: reportingCategoriesChartFiltersState,
}: {
  reportingFilters:
    | TSPCategoriesReportChartFilters
    | TDACategoriesReportChartFilters
}): TSPCategoriesReportChartFilters | TDACategoriesReportChartFilters =>
  reportingCategoriesChartFiltersState
