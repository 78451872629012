import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { EEntityType, EParamSortOrder, EStatus } from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectProductsTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import {
  AppContext,
  AdGroupContext,
  CampaignContext,
  ISPReportProductsTable,
  ESPReportProductsColumnHeader,
} from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDAProductsTableHeaders, TSPProductsTableHeaders } from '../models'
import { getTableAndChartProductsFn } from '../helpers/get-table-and-chart-products-fn'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import { TDatasetWhitelistKey, TGlobalTables } from '../../models'
import { TableDataCellCpc } from '../../components/table-data-cell/TableDataCellCpc'
import { TableDataCellClicks } from '../../components/table-data-cell/TableDataCellClicks'
import { TableDataCellAvgRank } from '../../components/table-data-cell/TableDataCellAvgRank'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import { TableDataCellImage } from '../../components/table-data-cell/TableDataCellImage'
import { TableDataCellAdGroups } from '../../components/table-data-cell/TableDataCellAdGroups'
import { TableDataCellCampaigns } from '../../components/table-data-cell/TableDataCellCampaigns'
import { TableDataCellStatusProductWithUpdate } from '../../components/table-data-cell/TableDataCellStatusProductWithUpdate'
import {
  EDAReportProductsColumnHeader,
  EDatasetKey,
  EParamChannelTypeGlobal,
  IDAReportProductsTable,
} from '../../../../models/core'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { TableDataCellInStock } from '../../components/table-data-cell/TableDataCellInStock'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import useShowToast from '../../../../hooks/useShowToast'
import { TablePreloader } from '../../components/table-preloader'

type TProductsTableHeaders = TSPProductsTableHeaders & TDAProductsTableHeaders
type IReportProductsTable = ISPReportProductsTable | IDAReportProductsTable

export const ReportingProductsTableWrapper = ({
  onTableDataChange,
  channelType,
}: {
  onTableDataChange: (productsTableData: ISPReportProductsTable) => void
  channelType?: EParamChannelTypeGlobal
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const { startDate, endDate, timeWindow, tzZone, productId, platformType } =
    useSelector(selectProductsTableReportingFilters)
  const refreshTable = useSelector(selectRefreshTable)

  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const hookFn = getTableAndChartProductsFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const campaignId = campaign?.id
  const adGroupId = adGroup?.id

  const [sortBy, setSortBy] = useState<TProductsTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()
  const [tableParamsUpdated, setTableParamsUpdated] = useState<boolean>()

  const [errorUpdateStatus, setErrorUpdateStatus] = useState<TTranslationKey>()
  const [showUpdateStatusErrorToast, setShowUpdateStatusErrorToast] =
    useShowToast(false)

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    campaignId,
    adGroupId,
    channelType,
    productId,
    platformType,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TProductsTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportProductsTable)

  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
      EDatasetKey.ProductSp,
  })

  const processTableDataResponse = (tableDataRes: IReportProductsTable) => {
    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === ESPReportProductsColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TProductsTableHeaders]

        switch (header) {
          case ESPReportProductsColumnHeader.Image:
          case EDAReportProductsColumnHeader.Image:
            dataCell = <TableDataCellImage dataValue={dataValue?.toString()} />
            break
          case ESPReportProductsColumnHeader.Name:
          case EDAReportProductsColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case ESPReportProductsColumnHeader.Status:
          case EDAReportProductsColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusProductWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                orgType={orgType}
                id={row.id}
                key={`${row.id}-${sortBy}-${sortOrder}`}
                setErrorUpdateStatus={setErrorUpdateStatus}
                setShowUpdateStatusErrorToast={setShowUpdateStatusErrorToast}
              />
            )
            break
          case ESPReportProductsColumnHeader.Campaigns:
          case EDAReportProductsColumnHeader.Campaigns:
            dataCell = (
              <TableDataCellCampaigns dataValue={dataValue as Array<string>} />
            )
            break
          case ESPReportProductsColumnHeader.Adgroups:
          case EDAReportProductsColumnHeader.Adgroups:
            dataCell = (
              <TableDataCellAdGroups dataValue={dataValue as Array<string>} />
            )
            break
          case ESPReportProductsColumnHeader.Impressions:
          case EDAReportProductsColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case ESPReportProductsColumnHeader.Clicks:
          case EDAReportProductsColumnHeader.Clicks:
            dataCell = <TableDataCellClicks dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.Ctr:
          case EDAReportProductsColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.Costs:
          case EDAReportProductsColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.Carts:
          case EDAReportProductsColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.Purchases:
          case EDAReportProductsColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.Revenue:
          case EDAReportProductsColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.Roas:
          case EDAReportProductsColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.InStock:
          case EDAReportProductsColumnHeader.InStock:
            dataCell = <TableDataCellInStock dataValue={Boolean(dataValue)} />
            break
          // SP Exclusive
          case ESPReportProductsColumnHeader.Cpc:
            dataCell = <TableDataCellCpc dataValue={Number(dataValue)} />
            break
          case ESPReportProductsColumnHeader.AvgRank:
            dataCell = <TableDataCellAvgRank dataValue={Number(dataValue)} />
            break
          // DA Exclusive
          case EDAReportProductsColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          case EDAReportProductsColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EDAReportProductsColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EDAReportProductsColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EDAReportProductsColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EDAReportProductsColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EDAReportProductsColumnHeader.PvRoas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate) {
      setTableParamsUpdated(true)
    }
  }, [startDate, endDate, channelType])

  useEffect(() => {
    if (sortBy || sortOrder) {
      setTableParamsUpdated(true)
    }
  }, [sortBy, sortOrder])

  useEffect(() => {
    if (tableParamsUpdated) {
      refetch()
      setTableParamsUpdated(false)
    }
  }, [tableParamsUpdated])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(
        tableDataResponse as ISPReportProductsTable & IDAReportProductsTable
      )
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupProduct, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateStatusErrorToast}
        onClose={() => {
          setShowUpdateStatusErrorToast(false)
          setErrorUpdateStatus(undefined)
        }}
        translationKey={errorUpdateStatus}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
