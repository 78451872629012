import {
  TDAAudiencesReportChartFilters,
  TDOOHAudiencesReportChartFilters,
} from '../../../models/types'

export const selectAudienceChartReportingFilters = ({
  reportingFilters: reportingAudiencesChartFiltersState,
}: {
  reportingFilters:
    | TDAAudiencesReportChartFilters
    | TDOOHAudiencesReportChartFilters
}): TDAAudiencesReportChartFilters | TDOOHAudiencesReportChartFilters =>
  reportingAudiencesChartFiltersState
