import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import {
  EOrgType,
  EParamChannelType,
  ERetailerReportPageType,
  EUserRole,
} from '@unfoldrtech/portal-mic'
import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import ReportPageWrapper from '../ReportPageWrapper'
import { lazyRetry } from '../../utils/lazyRetry'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import {
  PORTAL_FF_DA,
  PORTAL_FF_DA_PURCHASE_ATTRIBUTION,
  PORTAL_FF_DOOH_REPORTING_VIEW,
  PORTAL_FF_REPORTING_SEARCH_TERMS,
  PORTAL_FF_REPORTING_V3_POSITIONS,
  PORTAL_FF_REPORTING_V_3_AUDIENCES,
  PORTAL_FF_REPORTING_V_3_CAMPAIGNS,
  PORTAL_FF_REPORTING_V_3_KEYWORDS,
  PORTAL_FF_REPORTING_V_3_PRODUCTS,
  PORTAL_FF_REPORTING_V_3_CATEGORIES,
  PORTAL_FF_SP,
} from '../../utils/constants'
import { ReportingDOOHLocationTemplate } from '../ReportPageWrapperV3/dooh-location/components/ReportingDOOHLocationTemplate'
import { ReportingAudienceTemplate } from '../ReportPageWrapperV3/audience/components/ReportingAudienceTemplate'
import { ReportingProductsTemplate } from '../ReportPageWrapperV3/products/components/ReportingProductsTemplate'
import { ReportingDAPositionsTemplate } from '../ReportPageWrapperV3/da-positions/components/ReportingDAPositionsTemplate'
import NotFound from '../../pages/NotFound'
import { EChannelTypeGlobal, EParamChannelTypeGlobal } from '../../models/core'
import { ReportingCampaignsTemplate } from '../ReportPageWrapperV3/campaigns/components/ReportingCampaignsTemplate'
import { ReportingKeywordsTemplate } from '../ReportPageWrapperV3/keywords/components/ReportingKeywordsTemplate'
import { ReportingCategoriesTemplate } from '../ReportPageWrapperV3/categories/components/ReportingCategoriesTemplates'

const Overview = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Analytics/Overview'))
)
const Campaigns = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Analytics/Campaigns'))
)
const AdGroups = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Analytics/AdGroups'))
)
const AdvertisersReport = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Analytics/AdvertisersReport')
  )
)

const RetailerAnalyticsRoutes = () => {
  const featureFlags = useFeatureFlags()

  const isPortalSPFlagEnabled = featureFlags[PORTAL_FF_SP]
  const isPortalDAFlagEnabled = featureFlags[PORTAL_FF_DA]
  const isPortalDOOHReportingViewFlagEnabled =
    featureFlags[PORTAL_FF_DOOH_REPORTING_VIEW]
  const isPortalReportingV3ProductsFlagEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_PRODUCTS]
  const isPortalReportingV3PositionsFlagEnabled =
    featureFlags[PORTAL_FF_REPORTING_V3_POSITIONS]
  const isPortalDAPurchaseAttributionEnabled =
    featureFlags[PORTAL_FF_DA_PURCHASE_ATTRIBUTION]
  const isPortalReportingV3SPOrDAEnabled =
    (isPortalSPFlagEnabled && isPortalReportingV3ProductsFlagEnabled) ||
    (isPortalDAFlagEnabled &&
      isPortalDAPurchaseAttributionEnabled &&
      isPortalReportingV3ProductsFlagEnabled)
  const isPortalReportingV3CampaignsEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_CAMPAIGNS]
  const isPortalReportingV3KeywordsEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_KEYWORDS]
  const isPortalReportingV3AudiencesFlagEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_AUDIENCES]
  const isAudiencesEnabled =
    (isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) &&
    isPortalReportingV3AudiencesFlagEnabled
  const isPortalReportingV3CategoriesEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_CATEGORIES]

  return (
    <PortalRoutes>
      <Route
        path=""
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <Overview />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="advertisers-all"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="retailer.nav.advertisers"
                orgType={EOrgType.Retailer}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="campaigns-all"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3CampaignsEnabled ? (
                <ReportingCampaignsTemplate />
              ) : (
                <ReportPageWrapper
                  title="advertiser.nav.campaigns"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={
                    ERetailerReportPageType.CampaignsAdvertiser
                  }
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="campaigns-sp"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3CampaignsEnabled ? (
                <ReportingCampaignsTemplate
                  channelType={EChannelTypeGlobal.SponsoredProduct}
                />
              ) : (
                <ReportPageWrapper
                  title="reporting.header.campaigns.sponsoredProducts"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={
                    ERetailerReportPageType.CampaignsAdvertiser
                  }
                  channelType={EParamChannelType.SponsoredProduct}
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
          /* exact */
        />
        {isPortalDAFlagEnabled && (
          <Route
            path="campaigns-da"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3CampaignsEnabled ? (
                  <>
                    <ReportingCampaignsTemplate
                      channelType={EChannelTypeGlobal.DisplayAd}
                    />
                  </>
                ) : (
                  <ReportPageWrapper
                    title="reporting.header.campaigns.displayAds"
                    orgType={EOrgType.Retailer}
                    retailerReportPageType={
                      ERetailerReportPageType.CampaignsAdvertiser
                    }
                    channelType={EParamChannelType.Da}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="campaigns-dooh"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3CampaignsEnabled ? (
                  <>
                    <ReportingCampaignsTemplate
                      channelType={EChannelTypeGlobal.DigitalOutOfHome}
                    />
                  </>
                ) : (
                  <ReportPageWrapper
                    title="reporting.header.campaigns.dooh"
                    orgType={EOrgType.Retailer}
                    retailerReportPageType={
                      ERetailerReportPageType.CampaignsAdvertiser // EEntityType.Campaign
                    }
                    channelType={EParamChannelType.Dooh}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalSPFlagEnabled && isPortalReportingV3ProductsFlagEnabled && (
          <Route
            path="products-sp"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingProductsTemplate
                  channelType={EParamChannelTypeGlobal.SponsoredProduct}
                />
              </AuthenticatedRoute>
            }
          />
        )}

        {isPortalSPFlagEnabled && !isPortalReportingV3ProductsFlagEnabled && (
          <Route
            path="products-sp"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="products"
                  retailerReportPageType={ERetailerReportPageType.Products}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}

        {isPortalDAFlagEnabled &&
          isPortalDAPurchaseAttributionEnabled &&
          isPortalReportingV3ProductsFlagEnabled && (
            <Route
              path="products-da"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                  <ReportingProductsTemplate
                    channelType={EParamChannelTypeGlobal.DisplayAd}
                  />
                </AuthenticatedRoute>
              }
            />
          )}
      </Route>

      {/* Analytics pages for an advertiser showing campaigns */}
      <Route
        path="brands/:advertiserId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <AdvertisersReport />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="campaigns-all"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3CampaignsEnabled ? (
                <ReportingCampaignsTemplate />
              ) : (
                <ReportPageWrapper
                  title="advertiser.nav.campaigns"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Campaigns}
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="campaigns-sp"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3CampaignsEnabled ? (
                <ReportingCampaignsTemplate
                  channelType={EChannelTypeGlobal.SponsoredProduct}
                />
              ) : (
                <ReportPageWrapper
                  title="reporting.header.campaigns.sponsoredProducts"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Campaigns}
                  channelType={EParamChannelType.SponsoredProduct}
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
          /* exact */
        />

        {isPortalDAFlagEnabled && (
          <Route
            path="campaigns-da"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3CampaignsEnabled ? (
                  <ReportingCampaignsTemplate
                    channelType={EChannelTypeGlobal.DisplayAd}
                  />
                ) : (
                  <ReportPageWrapper
                    title="reporting.header.campaigns.displayAds"
                    orgType={EOrgType.Retailer}
                    retailerReportPageType={ERetailerReportPageType.Campaigns}
                    channelType={EParamChannelType.Da}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="campaigns-dooh"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3CampaignsEnabled ? (
                  <ReportingCampaignsTemplate
                    channelType={EChannelTypeGlobal.DigitalOutOfHome}
                  />
                ) : (
                  <ReportPageWrapper
                    title="reporting.header.campaigns.dooh"
                    orgType={EOrgType.Retailer}
                    retailerReportPageType={ERetailerReportPageType.Campaigns}
                    channelType={EParamChannelType.Dooh}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
      </Route>

      {/* Analytics pages for an advertiser showing adgroups for a campaign */}
      <Route
        path="brands/:advertiserId/campaigns/:campaignId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <Campaigns />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="adgroups"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="reporting.header.adGroups"
                orgType={EOrgType.Retailer}
                retailerReportPageType={ERetailerReportPageType.Adgroups}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.creatives"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isPortalReportingV3SPOrDAEnabled && (
          <Route
            path="products"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingProductsTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3PositionsFlagEnabled ? (
                  <ReportingDAPositionsTemplate />
                ) : (
                  <ReportPageWrapper
                    title="reporting.header.positions"
                    orgType={EOrgType.Retailer}
                    retailerReportPageType={ERetailerReportPageType.Positions}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="screen"
            element={
              <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                <ReportPageWrapper
                  title="reporting.header.screens"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={'screens' as ERetailerReportPageType}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}
        {isAudiencesEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>

      {/* Analytics pages  for an advertiser showing products/keywords/categories for a campaign adgroup */}
      <Route
        path="brands/:advertiserId/campaigns/:campaignId/adgroups/:adgroupId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <AdGroups />
          </AuthenticatedRoute>
        }
      >
        {isPortalReportingV3SPOrDAEnabled && (
          <Route
            path="products"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingProductsTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalSPFlagEnabled && !isPortalReportingV3ProductsFlagEnabled && (
          <Route
            path="products"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="products"
                  retailerReportPageType={ERetailerReportPageType.Products}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}

        <Route
          path="categories"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3CategoriesEnabled ? (
                <ReportingCategoriesTemplate />
              ) : (
                <ReportPageWrapper
                  title="categories"
                  retailerReportPageType={ERetailerReportPageType.Categories}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="keywords"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3KeywordsEnabled ? (
                <ReportingKeywordsTemplate />
              ) : (
                <ReportPageWrapper
                  title="keywords"
                  retailerReportPageType={ERetailerReportPageType.Keywords}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
        />
        {featureFlags[PORTAL_FF_REPORTING_SEARCH_TERMS] && (
          <Route
            path="search-terms"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="search.terms"
                  subTitle="search.terms.description"
                  retailerReportPageType={
                    ERetailerReportPageType.KeywordsInsights
                  }
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  virtual={true}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="pagePosition"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="pagePosition"
                  retailerReportPageType={ERetailerReportPageType.Pages}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                  channelType={EParamChannelType.Da}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3PositionsFlagEnabled ? (
                  <ReportingDAPositionsTemplate />
                ) : (
                  <ReportPageWrapper
                    title="positions"
                    retailerReportPageType={ERetailerReportPageType.Positions}
                    orgType={EOrgType.Retailer}
                    navigateDisabled={true}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
          />
        )}
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="creatives"
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="locations"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDOOHLocationTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {isAudiencesEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>

      {/* Analytics pages showing adgroups for a campaign */}
      <Route
        path="campaigns/:campaignId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <Campaigns />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="adgroups"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <ReportPageWrapper
                title="reporting.header.adGroups"
                orgType={EOrgType.Retailer}
                retailerReportPageType={ERetailerReportPageType.Adgroups}
                showSwitch={false}
              />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3PositionsFlagEnabled ? (
                  <ReportingDAPositionsTemplate />
                ) : (
                  <ReportPageWrapper
                    title="reporting.header.positions"
                    orgType={EOrgType.Retailer}
                    retailerReportPageType={ERetailerReportPageType.Positions}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalReportingV3SPOrDAEnabled && (
          <Route
            path="products"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingProductsTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="reporting.header.creatives"
                  orgType={EOrgType.Retailer}
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isAudiencesEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>

      {/* Analytics pages showing products/keywords/categories for a campaign adgroup */}
      <Route
        path="campaigns/:campaignId/adgroups/:adgroupId"
        element={
          <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
            <AdGroups />
          </AuthenticatedRoute>
        }
      >
        {isPortalReportingV3SPOrDAEnabled && (
          <Route
            path="products"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingProductsTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalSPFlagEnabled && !isPortalReportingV3ProductsFlagEnabled && (
          <Route
            path="products"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="products"
                  retailerReportPageType={ERetailerReportPageType.Products}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
            /* exact */
          />
        )}

        <Route
          path="categories"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3CategoriesEnabled ? (
                <ReportingCategoriesTemplate />
              ) : (
                <ReportPageWrapper
                  title="categories"
                  retailerReportPageType={ERetailerReportPageType.Categories}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
          /* exact */
        />

        <Route
          path="keywords"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              {isPortalReportingV3KeywordsEnabled ? (
                <ReportingKeywordsTemplate />
              ) : (
                <ReportPageWrapper
                  title="keywords"
                  retailerReportPageType={ERetailerReportPageType.Keywords}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              )}
            </AuthenticatedRoute>
          }
        />
        {featureFlags[PORTAL_FF_REPORTING_SEARCH_TERMS] && (
          <Route
            path="search-terms"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="search.terms"
                  subTitle="search.terms.description"
                  retailerReportPageType={
                    ERetailerReportPageType.KeywordsInsights
                  }
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  virtual={true}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {(isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) && (
          <Route
            path="creatives"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportPageWrapper
                  title="creatives"
                  retailerReportPageType={ERetailerReportPageType.Creatives}
                  orgType={EOrgType.Retailer}
                  navigateDisabled={true}
                  showSwitch={false}
                />
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDAFlagEnabled && (
          <Route
            path="positions"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                {isPortalReportingV3PositionsFlagEnabled ? (
                  <ReportingDAPositionsTemplate />
                ) : (
                  <ReportPageWrapper
                    title="positions"
                    retailerReportPageType={ERetailerReportPageType.Positions}
                    orgType={EOrgType.Retailer}
                    navigateDisabled={true}
                    showSwitch={false}
                  />
                )}
              </AuthenticatedRoute>
            }
          />
        )}
        {isPortalDOOHReportingViewFlagEnabled && (
          <Route
            path="locations"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingDOOHLocationTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
        {isAudiencesEnabled && (
          <Route
            path="audiences"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <ReportingAudienceTemplate />
              </AuthenticatedRoute>
            }
          />
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </PortalRoutes>
  )
}

export default RetailerAnalyticsRoutes
