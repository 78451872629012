import React from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import Spinner from 'react-bootstrap/Spinner'
import { FormCheckInputProps } from 'react-bootstrap/esm/FormCheckInput'
import {
  Action,
  Border,
  Size,
  TActionMixin,
  TSizeMixin,
} from '../../styles/mixins'
import { Container, SVGContainer } from '../../containers'

export interface ISwitchProps
  extends TSizeMixin,
    TActionMixin,
    Omit<FormCheckInputProps, 'height' | 'width' | 'size' | 'color'> {
  isLoading?: boolean
  checkedColor?: string
}

export const SSwitch = styled(Form.Switch)<TSizeMixin & TActionMixin>`
  & .form-check-input {
    ${Border({
      border: {
        radius: '2px',
      },
    })}

    ${(props: TSizeMixin) =>
      Size({
        height: 'var(--font-size-h2)',
        width: 'var(--logo-size-no)',
        ...props,
      })};

    ${(props: TActionMixin) =>
      Action({
        cursor: 'pointer',
        backgroundColor:
          props.backgroundColor || 'var(--background-color-reject-button)',
        border: props.border || {
          color: 'var(--background-color-reject-button)',
        },
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-square-fill' viewBox='-4 0 20 16'%3E%3Cpath d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z'/%3E%3C/svg%3E")`,

        focusAction: {
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-square-fill' viewBox='-4 0 20 16'%3E%3Cpath d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z'/%3E%3C/svg%3E")`,
        },
      })}

    &:checked {
      ${(props) =>
        Action({
          backgroundColor:
            props.checkedColor || 'var(--background-color-approve-button)',
          border: {
            color:
              props.checkedColor || 'var(--background-color-approve-button)',
          },
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-square-fill' viewBox='0 0 20 16'%3E%3Cpath d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z'/%3E%3C/svg%3E")`,

          focusAction: {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-square-fill' viewBox='0 0 20 16'%3E%3Cpath d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z'/%3E%3C/svg%3E")`,
          },
        })}
      &::after {
        margin: ${(props) => (props.height ? '0px 28px' : '2px 38px')};
      }
    }

    &::after {
      content: '|||';
      // position: absolute;
      margin: ${(props) => (props.height ? '0px 8px' : '2px 12px')};
      color: var(--border-color-default);
      font-family: 'Roboto';
    }
  }
`

export const StyledSwitch = ({ isLoading, ...props }: ISwitchProps) => (
  <Container>
    {isLoading ? (
      <SVGContainer
        height={props.height || 'var(--icon-size-lg)'}
        display="flex"
        alignItems="center"
      >
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </SVGContainer>
    ) : (
      <SSwitch {...props} disabled={isLoading || props.disabled} />
    )}
  </Container>
)
