import {
  TDACampaignsReportTableFilters,
  TDOOHCampaignsReportTableFilters,
  TGenericCampaignsReportTableFilters,
  TSPCampaignsReportTableFilters,
} from '../../../models/types'

export const selectCampaignsTableReportingFilters = ({
  reportingFilters: reportingCampaignsTableFiltersState,
}: {
  reportingFilters:
    | TGenericCampaignsReportTableFilters
    | TSPCampaignsReportTableFilters
    | TDACampaignsReportTableFilters
    | TDOOHCampaignsReportTableFilters
}):
  | TGenericCampaignsReportTableFilters
  | TSPCampaignsReportTableFilters
  | TDACampaignsReportTableFilters
  | TDOOHCampaignsReportTableFilters => reportingCampaignsTableFiltersState
