import React from 'react'
import { EDealType } from '../../../models/core'
import I18NText from '../../I18NText'

export function CurrentGlobalDealTypeText({
  dealType,
}: {
  dealType?: EDealType
}) {
  if (dealType === EDealType.ExclusiveDeal) {
    return <I18NText id="direct.deals.type.exclusive" />
  }
  if (dealType === EDealType.StandardDeal) {
    return <I18NText id="direct.deals.type.standard" />
  }
  if (dealType === EDealType.Programmatic) {
    return <I18NText id="programmatic" />
  }
  return <></>
}
