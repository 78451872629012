/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, ReactNode, Suspense } from 'react'
import { useIntl } from 'react-intl'
import Placeholder from 'react-select/src/components/Placeholder'
import { IReportFiltersProps } from '../../../models'
import { Container, StickyContainer, Text } from '../../Global'
import I18NText from '../../I18NText'
import Filters from '../../ReportPageTemplate/Filters'
import { Preloader } from '../../Preloader/Preloader'
import { TablePreloader } from './table-preloader'

interface IReportPageTemplateLayout {
  title: string
  tableWrapper: ReactNode | ReactNode[]
  chartWrapper: ReactNode | ReactNode[]
  subTitle?: string
  isDateRangeFilterDisabled?: boolean
  isExportDisabled?: boolean
}

export const ReportPageTemplateLayout = ({
  title,
  tableWrapper,
  chartWrapper,
  subTitle,
  onExportData,
  isDateRangeFilterDisabled = false,
  isExportDisabled = false,
}: IReportPageTemplateLayout &
  Pick<IReportFiltersProps, 'onExportData'>): ReactElement => {
  const intl = useIntl()

  return (
    <Container
      height="100%"
      overflow="auto"
      flex="1"
      padding="0 var(--gutter-4x)"
    >
      <StickyContainer
        display="flex"
        alignItems="center"
        backgroundColor="var(--background-color-default)"
        padding="var(--padding-default) 0"
        paddingBottom={subTitle && 0}
        borderBottom={
          !subTitle
            ? {
                width: '1px',
                style: 'solid',
                color: 'var(--border-color-default)',
              }
            : undefined
        }
      >
        <Container
          flex="1"
          maxWidth="100%"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          <Text preset="h3" data-testid="report-title">
            {intl.formatMessage({
              id: title,
            })}
          </Text>
        </Container>
        <Filters
          onExportData={onExportData}
          isDateRangeFilterDisabled={isDateRangeFilterDisabled}
          isExportDisabled={isExportDisabled}
        />
      </StickyContainer>
      {subTitle && (
        <StickyContainer
          paddingRight="var(--gutter-4x)"
          paddingTop="var(--gutter-2x)"
          paddingBottom="var(--padding-default)"
          borderBottom={{
            width: '1px',
            style: 'solid',
            color: 'var(--border-color-default)',
          }}
          top="var(--gutter-10x)"
          backgroundColor="var(--background-color-default)"
        >
          <I18NText
            id={subTitle}
            preset="paragraph opensans"
            textTransform="unset"
            opacity="0.65"
            fontSize="var(--font-size-sm)"
          />
        </StickyContainer>
      )}

      <Container data-testid="report-layout-chart-wrapper">
        {chartWrapper}
      </Container>
      <Suspense fallback={<TablePreloader />}>
        <Container data-testid="report-layout-table-wrapper">
          {tableWrapper}
        </Container>
      </Suspense>
    </Container>
  )
}
