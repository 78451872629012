import {
  TDAKeywordsReportTableFilters,
  TSPKeywordsReportTableFilters,
} from '../../../models/types'

export const selectKeywordsTableReportingFilters = ({
  reportingFilters: reportingKeywordsTableFiltersState,
}: {
  reportingFilters:
    | TSPKeywordsReportTableFilters
    | TDAKeywordsReportTableFilters
}): TSPKeywordsReportTableFilters | TDAKeywordsReportTableFilters =>
  reportingKeywordsTableFiltersState
