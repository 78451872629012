import {
  EDealType,
  EEntityType,
  EOrgType,
  EStatus,
  EUserRole,
  IEntity,
} from '@unfoldrtech/portal-mic'
import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  CurrentStatusBadge,
  HoverContainer,
  IconArrowDownSmall,
  SVGContainer,
  StyledAntDropdown,
  StyledAntMenu,
  StyledCircle,
  TooltipWrapper,
} from '../../../Global'
import { isUserAuthorised } from '../../../../utils/userAuthorisation'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
  EDeletedStatus,
  TTranslationKey,
} from '../../../../models'
import useUpdateEntityStatus from '../../../../hooks/useUpdateEntityStatus'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import I18NText from '../../../I18NText'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import useShowToast from '../../../../hooks/useShowToast'
import Loading from '../../../Loading'
import { setRefreshTable } from '../../../../store/refreshTable'

export const TableDataCellStatusAudiencesWithUpdate = ({
  dataValue,
  orgType,
  id,
}: {
  dataValue: EStatus
  orgType: EOrgType
  id: number
}) => {
  const [appContext] = useContext(AppContext)
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()
  const dispatch = useDispatch()
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const isDirectDeal = campaign.dealType !== EDealType.Programmatic || true // remove "|| true" once entityStatus audience is supported
  const userRole = appContext.userClaims?.userRole ?? EUserRole.None
  const isMutateNotAllowed = !isUserAuthorised(
    userRole,
    EUserRole.AdvertiserCampaigns
  )
  const isSwitchDisabled = dataValue === EStatus.Disabled
  const showSwitch = orgType === EOrgType.Advertiser
  const isDisabled = (isMutateNotAllowed || isSwitchDisabled) && showSwitch
  const isDeleted =
    (dataValue as EStatus | EDeletedStatus) === EDeletedStatus.Deleted
  const tooltipKey: TTranslationKey = 'entity.disabled'

  const {
    mutate: updateAudiencesStatus,
    error: errorUpdateAudiences,
    isError: isUpdateAudiencesError,
    isLoading: isUpdateAudiencesStatusLoading,
    isSuccess: isUpdateAudiencesStatusSuccess,
    reset: resetUpdateAudiencesStatusMutate,
  } = useUpdateEntityStatus({
    advertiserId,
    retailerId,
  })
  const [showUpdateAudiencesErrorToast, setShowUpdateAudiencesErrorToast] =
    useShowToast(isUpdateAudiencesError)

  useEffect(() => {
    if (isUpdateAudiencesStatusSuccess) {
      dispatch(setRefreshTable({ refreshTable: true }))
    }
  }, [isUpdateAudiencesStatusSuccess])

  return (
    <>
      <TooltipWrapper
        tooltipKey={tooltipKey}
        showTooltip={isDisabled && !isDeleted}
      >
        <StyledAntDropdown
          overlay={
            <StyledAntMenu
              onClick={({ key }) => {
                const status = key as EStatus

                resetUpdateAudiencesStatusMutate()

                updateAudiencesStatus({
                  id,
                  entityType: EEntityType.AdGroupAudiences,
                  entity: {
                    status,
                    campaignId: campaign.id,
                    adGroupId: adGroup.id,
                  } as IEntity,
                })
              }}
            >
              <StyledAntMenu.Item key={EStatus.Active}>
                <Container display="flex" alignItems="center">
                  <StyledCircle
                    backgroundColor="var(--badge-color-active)"
                    width="8px"
                    height="8px"
                  />
                  <Container marginLeft="var(--gutter-default)">
                    <I18NText
                      fontFamily="OpenSans"
                      size="var(--font-size-ps)"
                      textTransform="capitalize"
                      id="active"
                      data-testid="table-status-active-option"
                    />
                  </Container>
                </Container>
              </StyledAntMenu.Item>
              <StyledAntMenu.Item key={EStatus.Inactive} danger>
                <Container display="flex" alignItems="center">
                  <StyledCircle
                    backgroundColor="var(--badge-color-inactive)"
                    width="8px"
                    height="8px"
                  />
                  <Container marginLeft="var(--gutter-default)">
                    <I18NText
                      fontFamily="OpenSans"
                      size="var(--font-size-ps)"
                      textTransform="capitalize"
                      id="inactive"
                      data-testid="table-status-inactive-option"
                    />
                  </Container>
                </Container>
              </StyledAntMenu.Item>
            </StyledAntMenu>
          }
          disabled={isDisabled || isDeleted || !showSwitch || isDirectDeal}
          overlayStyle={{ zIndex: 1000 }}
          key={Number(Math.random() * 1000).toString()}
        >
          <HoverContainer
            preset="paragraph opensans semibold"
            display="flex"
            alignItems="center"
            height="100%"
            width="100%"
            cursor="pointer"
            onClick={(e) => {
              e.preventDefault()
            }}
            data-testid="report-table-status-dropdown"
          >
            <CurrentStatusBadge status={dataValue as EStatus} />
            {!isDisabled && !isDeleted && showSwitch && !isDirectDeal && (
              <SVGContainer
                preset="paragraph small"
                margin="0 0 0 var(--margin-default)"
                className="hover-items"
              >
                <IconArrowDownSmall />
              </SVGContainer>
            )}
          </HoverContainer>
        </StyledAntDropdown>
      </TooltipWrapper>
      <ErrorToast
        show={showUpdateAudiencesErrorToast}
        onClose={() => setShowUpdateAudiencesErrorToast(false)}
        translationKey={
          errorUpdateAudiences?.response?.data
            .errorKey as unknown as TTranslationKey
        }
      />
      <Loading show={isUpdateAudiencesStatusLoading} />
    </>
  )
}
