import styled from 'styled-components'
import { StyledButton } from './StyledButton'
import { Action } from '../styles/mixins'

export const RejectButton = styled(StyledButton)`
  ${Action({
    backgroundColor: 'var(--background-color-reject-button)',

    hoverAction: {
      opacity: '0.5',
      backgroundColor: 'var(--background-color-reject-button)',
    },

    disableAction: {
      opacity: '0.5',
      backgroundColor: 'var(--background-color-reject-button)',
    },
  })}
`
