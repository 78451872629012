import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { EEntityType, EParamSortOrder, EStatus } from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectCategoriesTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { AdGroupContext, AppContext, CampaignContext } from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDACategoriesTableHeaders, TSPCategoriesTableHeaders } from '../models'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import { TDatasetWhitelistKey, TGlobalTables } from '../../models'
import { TableDataCellCpc } from '../../components/table-data-cell/TableDataCellCpc'
import { TableDataCellClicks } from '../../components/table-data-cell/TableDataCellClicks'
import { TableDataCellAvgRank } from '../../components/table-data-cell/TableDataCellAvgRank'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import {
  EChannelTypeGlobal,
  EDAReportCategoriesColumnHeader,
  ESPReportCategoriesColumnHeader,
  IDAReportCategoriesTable,
  ISPReportCategoriesTable,
} from '../../../../models/core'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { getCategoriesEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-categories-e-dataset-key-from-channel-type-global'
import { getTableAndChartCategoriesFn } from '../helpers/get-table-and-chart-categories-fn'
import { TableDataCellNewToBrandPurchases } from '../../components/table-data-cell/TableDataCellNewToBrandPurchases'
import { TableDataCellNewToBrandRevenue } from '../../components/table-data-cell/TableDataCellNewToBrandRevenue'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellStatusCategoryWithUpdate } from '../../components/table-data-cell/TableDataCellStatusCategoryWithUpdate'
import { TableDataCellBidPrice } from '../../components/table-data-cell/TableDataCellBidPrice'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellWinRate } from '../../components/table-data-cell/TableDataCellWinRate'
import useShowToast from '../../../../hooks/useShowToast'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import { TablePreloader } from '../../components/table-preloader'

type TCategoriesTableHeaders = TSPCategoriesTableHeaders &
  TDACategoriesTableHeaders
type IReportCategoriesTable =
  | ISPReportCategoriesTable
  | IDAReportCategoriesTable

export const ReportingCategoriesTableWrapper = ({
  onTableDataChange,
  channelType,
}: {
  onTableDataChange: (
    categoriesTableData: ISPReportCategoriesTable | IDAReportCategoriesTable
  ) => void
  channelType: EChannelTypeGlobal
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectCategoriesTableReportingFilters
  )

  const refreshTable = useSelector(selectRefreshTable)
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const campaignId = campaign?.id
  const adGroupId = adGroup?.id
  const hookFn = getTableAndChartCategoriesFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const [sortBy, setSortBy] = useState<TCategoriesTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()
  const [tableParamsUpdated, setTableParamsUpdated] = useState<boolean>()

  const [errorUpdateStatus, setErrorUpdateStatus] = useState<TTranslationKey>()
  const [showUpdateStatusErrorToast, setShowUpdateStatusErrorToast] =
    useShowToast(false)

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    channelType,
    campaignId,
    adGroupId,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    skip: !!channelType,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TCategoriesTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportCategoriesTable)

  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
      getCategoriesEDatasetKeyFromChannelTypeGlobal(channelType),
  })

  const processTableDataResponse = (tableDataRes: IReportCategoriesTable) => {
    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === ESPReportCategoriesColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TCategoriesTableHeaders]

        switch (header) {
          case ESPReportCategoriesColumnHeader.Name:
          case EDAReportCategoriesColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case ESPReportCategoriesColumnHeader.Status:
          case EDAReportCategoriesColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusCategoryWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                orgType={orgType}
                id={row.id}
                key={`${row.id}-${sortBy}-${sortOrder}`}
                setErrorUpdateStatus={setErrorUpdateStatus}
                setShowUpdateStatusErrorToast={setShowUpdateStatusErrorToast}
              />
            )
            break
          case ESPReportCategoriesColumnHeader.BidPrice:
          case EDAReportCategoriesColumnHeader.BidPrice:
            dataCell = <TableDataCellBidPrice dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.Impressions:
          case EDAReportCategoriesColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case ESPReportCategoriesColumnHeader.Clicks:
          case EDAReportCategoriesColumnHeader.Clicks:
            dataCell = <TableDataCellClicks dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.Ctr:
          case EDAReportCategoriesColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.Costs:
          case EDAReportCategoriesColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.Carts:
          case EDAReportCategoriesColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.Purchases:
          case EDAReportCategoriesColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.Revenue:
          case EDAReportCategoriesColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.Roas:
          case EDAReportCategoriesColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.ShareOfVoice:
          case EDAReportCategoriesColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break
          case ESPReportCategoriesColumnHeader.WinRate:
          case EDAReportCategoriesColumnHeader.WinRate:
            dataCell = <TableDataCellWinRate dataValue={Number(dataValue)} />
            break
          // SP Exclusive
          case ESPReportCategoriesColumnHeader.Cpc:
            dataCell = <TableDataCellCpc dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.AvgRank:
            dataCell = <TableDataCellAvgRank dataValue={Number(dataValue)} />
            break
          case ESPReportCategoriesColumnHeader.NewToBrandPurchases:
            dataCell = (
              <TableDataCellNewToBrandPurchases dataValue={Number(dataValue)} />
            )
            break
          case ESPReportCategoriesColumnHeader.NewToBrandRevenue:
            dataCell = (
              <TableDataCellNewToBrandRevenue dataValue={Number(dataValue)} />
            )
            break
          // DA Exclusive
          case EDAReportCategoriesColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EDAReportCategoriesColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EDAReportCategoriesColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EDAReportCategoriesColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EDAReportCategoriesColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EDAReportCategoriesColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate) {
      setTableParamsUpdated(true)
    }
  }, [startDate, endDate, channelType])

  useEffect(() => {
    if (sortBy || sortOrder) {
      setTableParamsUpdated(true)
    }
  }, [sortBy, sortOrder])

  useEffect(() => {
    if (tableParamsUpdated) {
      refetch()
      setTableParamsUpdated(false)
    }
  }, [tableParamsUpdated])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(
        tableDataResponse as ISPReportCategoriesTable & IDAReportCategoriesTable
      )
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupKeyword, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateStatusErrorToast}
        onClose={() => {
          setShowUpdateStatusErrorToast(false)
          setErrorUpdateStatus(undefined)
        }}
        translationKey={errorUpdateStatus}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
