import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingCampaignsChartV3 from '../../../../hooks/use-get-advertiser-reporting-campaigns-chart-v3'
import useGetAdvertiserReportingCampaignsTableV3 from '../../../../hooks/use-get-advertiser-reporting-campaigns-table-v3'
import useGetRetailerReportingCampaignsChartV3 from '../../../../hooks/use-get-retailer-reporting-campaigns-chart-v3'
import useGetRetailerReportingCampaignsTableV3 from '../../../../hooks/use-get-retailer-reporting-campaigns-table-v3'

export const getTableAndChartCampaignsFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingCampaignsTableV3
    chartFn = useGetAdvertiserReportingCampaignsChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingCampaignsTableV3
    chartFn = useGetRetailerReportingCampaignsChartV3
  }

  return { tableFn, chartFn }
}
