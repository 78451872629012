import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingKeywordsChartV3 from '../../../../hooks/use-get-advertiser-reporting-keywords-chart-v3'
import useGetAdvertiserReportingKeywordsTableV3 from '../../../../hooks/use-get-advertiser-reporting-keywords-table-v3'
import useGetRetailerReportingKeywordsChartV3 from '../../../../hooks/use-get-retailer-reporting-keywords-chart-v3'
import useGetRetailerReportingKeywordsTableV3 from '../../../../hooks/use-get-retailer-reporting-keywords-table-v3'

export const getTableAndChartKeywordsFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingKeywordsTableV3
    chartFn = useGetAdvertiserReportingKeywordsChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingKeywordsTableV3
    chartFn = useGetRetailerReportingKeywordsChartV3
  }

  return { tableFn, chartFn }
}
