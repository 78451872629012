import React from 'react'
import { CurrentGlobalDealTypeText } from '../../../Global/fonts/CurrentGlobalDealTypeText'
import { EDealType } from '../../../../models'

export const TableDataCellCampaignType = ({
  dataValue,
}: {
  dataValue: EDealType
}) => {
  return <CurrentGlobalDealTypeText dealType={dataValue} />
}
