import { EChannelTypeGlobal, EParamChannelTypeGlobal } from '../../models/core'

export function convertEParamChannelTypeGlobalToEChannelTypeGlobal(
  channelType?: EParamChannelTypeGlobal
) {
  switch (channelType) {
    case EParamChannelTypeGlobal.SponsoredProduct:
      return EChannelTypeGlobal.SponsoredProduct
    case EParamChannelTypeGlobal.DisplayAd:
      return EChannelTypeGlobal.DisplayAd
    case EParamChannelTypeGlobal.DigitalOutOfHome:
      return EChannelTypeGlobal.DigitalOutOfHome
    default:
      break
  }
}
